import React from "react"
import clsx from "clsx"
import * as styles from "./advs.module.scss"

type AdvItemProps = {
  title: string
  descr: string
  combined?: boolean
}

export const AdvItem = ({ title, descr, combined }: AdvItemProps) => (
  <>
    <p className={clsx(styles.title, { [styles.combined]: combined })}>{title}</p>
    <div className={clsx(styles.descr, { [styles.combined]: combined })}>{descr}</div>
  </>
)
