export const ADVANTAGES_STICKER_PAY = [
  {
    title: "Для iPhone",
    descr: "или Android без модуля NFC",
  },
  {
    title: "Бесплатно",
    descr: "при выполнении Правила 5х10",
  },
  {
    title: "Надежно",
    descr: "нужно установить свой ПИН-код",
  },
  {
    title: "Привязан к Халве",
    descr: `платите стикером и получайте супер-бонусы`,
  },
]
