import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { AdvItem } from "./AdvItem"

import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"

import * as styles from "./advs.module.scss"
import { ADVANTAGES_STICKER_PAY } from "./helper"

export interface Item {
  title: string
  descr: string
}

export type AdvantagesProps = {
  items: Item[]
  orderNum?: string
  buttonText?: string
  combined?: boolean
}

export function Advantages({
  items = ADVANTAGES_STICKER_PAY,
  orderNum,
  buttonText = "Оформить",
  combined,
}: AdvantagesProps) {
  const handleClick = () => {
    pushToDataLayer({
      event: "buttonClick",
      name: buttonText,
      placement: "middle_button_cash",
    })
    scrollToPersonalForm()
  }

  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <div className={clsx(styles.wrapper, { [styles.combined]: combined })}>
          {items?.map(({ title, descr }, i) => (
            <div className={clsx(styles.item, { [styles.combined]: combined })} key={i}>
              <AdvItem title={title} descr={descr} combined={combined} />
            </div>
          ))}
          <Button className={styles.mobBtn} onClick={handleClick}>
            {buttonText}
          </Button>
        </div>
      </Container>
    </section>
  )
}
