import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type BannerProps = {
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
}

const defaultTitle = `
Ваш друг рекомендует вам оформить стикер Pay!
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = "",
  orderNum,
}: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.container}>
        <div className={styles.secondContainer}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.text}>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </div>
      </Container>
    </section>
  )
}
